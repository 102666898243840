import type { CentraSelectionItem } from "@frend-digital/centra/schemas/selection/selectionProductSchema";
import type { CentraSelection } from "@frend-digital/centra/schemas/selection/selectionSchema";

import {
  generateCartSummary,
  getFirstImage,
  getSelectionItemCategory,
  getSelectionItemId,
  getSelectionItemLine,
  getSelectionItemName,
  getSelectionItemPriceEach,
  getSelectionItemPriceEachAsNumber,
  getSelectionItemPriceEachBeforeDiscount,
  getSelectionItemQuantity,
  getSelectionItemSize,
  getSelectionItemSku,
  getSelectionItemUri,
  getSelectionItemVariantName,
  getSelectionProductId,
} from "../selectionAtoms";

const generateCartItem = (item: CentraSelectionItem) => {
  const itemId = getSelectionItemId(item) ?? "";

  const name = getSelectionItemName(item) ?? "";
  const uri = getSelectionItemUri(item) ?? "";
  const variant = getSelectionItemVariantName(item) ?? "";
  const sku = getSelectionItemSku(item) ?? "";
  const size = getSelectionItemSize(item) ?? "";
  const shape = item?.product?.pr_shape;
  const series = item?.product?.pr_series;
  const priceAsNumber = getSelectionItemPriceEachAsNumber(item) ?? 0;
  const price = getSelectionItemPriceEach(item) ?? "";
  const discountedPrice = getSelectionItemPriceEachBeforeDiscount(item) ?? "";
  const quantity = getSelectionItemQuantity(item) ?? 0;
  const category = getSelectionItemCategory(item) ?? "";
  const productId = getSelectionProductId(item) ?? "";
  // const media = getFirstImage(item);
  const media = {
    standard: getFirstImage(item, "standard"),
    full: getFirstImage(item, "full"),
  };

  // TODO: why do we need both line and id if they have the same value?
  const id = getSelectionItemLine(item) ?? "";
  const line = item.line ?? "";

  return {
    name,
    series,
    shape,
    uri,
    variant,
    size,
    id,
    price,
    quantity,
    media,
    priceAsNumber,
    discountedPrice,
    itemId,
    line,
    sku,
    category,
    productId,
  };
};

export type FormattedCartItem = ReturnType<typeof generateCartItem>;

export const formatItems = (items?: CentraSelectionItem[]) => {
  return items?.map((item) => generateCartItem(item));
};

export const formatSelection = (data: CentraSelection) => {
  const formattedItems = data.selection?.items?.map((item) =>
    generateCartItem(item),
  );

  const generatedSummary = generateCartSummary(data);

  const summary = {
    ...generatedSummary,
  };

  const formattedData = {
    ...data,
    selection: {
      ...data.selection,
      items: formattedItems,
      summary,
    },
    // Keep original selection for optimistic update changes
    rawSelection: data.selection,
  };

  return formattedData;
};

export type FormattedSelection = ReturnType<typeof formatSelection>;

export default formatSelection;
