"use client";
import { StateCreator } from "zustand";
import { IState } from "../persistedStore";

export interface ISnackbar {
  id: string;
  text: string;
  status?: "success" | "error" | "info" | "warning";
}

export interface ISnackbarState {
  snackbars: ISnackbar[];
  addSnackbar: (snackbar: ISnackbar) => void;
  removeSnackbar: (id: string) => void;
  clearSnackbars: () => void;
}

const MAX_SNACKBARS = 15;

export const createSnackbarSlice: StateCreator<
  IState,
  [],
  [],
  ISnackbarState
> = (set) => ({
  snackbars: [],

  addSnackbar: (snackbar) => {
    set((state) => ({
      snackbars: [...state.snackbars, { ...snackbar }].slice(-MAX_SNACKBARS),
    }));
  },

  removeSnackbar: (id) => {
    set((state) => ({
      snackbars: state.snackbars.filter((snackbar) => snackbar.id !== id),
    }));
  },

  clearSnackbars: () => {
    set({ snackbars: [] });
  },
});

export default createSnackbarSlice;
