import {
  CentraProductAllPricesBase,
  CentraProductSinglePricesBase,
} from "../types/product";

export const isFullCentraProduct = <
  All extends CentraProductAllPricesBase,
  Single extends CentraProductSinglePricesBase,
>(
  product: All | Single,
): product is All => {
  const isFull = !("price" in product) || "objectID" in product;
  return isFull;
};
